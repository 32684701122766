import dayjs from "./dayjs";
import { useJobStore } from "@/store/job";
import { useCompanyStore } from "@/store/company";
import { useCompanyInformation } from "@/composables/use-company-information";
import { Job } from "@/dto/job/job";
import * as Sentry from "@sentry/vue";
import type { Candidate } from "@/dto/candidate/candidate";
import type { Coordinates } from "@/types";

export function jobExpired(job: Job) {
  return dayjs(job.expires_at).toDate() < dayjs().toDate();
}

export function jobActive(job: Job) {
  return !job.archived && !job.expired;
}

export function createJobWithCheck() {
  const companyStore = useCompanyStore();
  const { hasCredits } = storeToRefs(companyStore);
  const { hasCompanyInformation, hasBillingInformation } = useCompanyInformation();

  if (!hasCredits.value)
    return companyStore.toggleCantCreateJobModal({
      active: true,
      description: "Please purchase at least one Job Credit before posting a job.",
      action: () => navigateTo(ROUTES.dashboardPayments),
      title: "Purchase Job Credits",
      buttonText: "Purchase",
    });

  if (!hasCompanyInformation.value)
    return companyStore.toggleCantCreateJobModal({
      active: true,
      description: "Please complete your company information before posting a job.",
      action: () => navigateTo(ROUTES.dashboardCompanySettings),
      title: "Complete Company Information",
      buttonText: "Complete",
    });

  if (!hasBillingInformation.value) 
    return companyStore.toggleCantCreateJobModal({
      active: true,
      description: "Please complete your billing information before posting a job.",
      action: () => navigateTo(ROUTES.dashboardBillingSettings),
      title: "Complete Billing Information",
      buttonText: "Complete",
    });

  navigateTo(ROUTES.dashboardJobsCreate);
}

export const getZoomForJobMap = (radius: number | undefined) => {
  const radiusInMetres = radius ? radius * 1000 : 0;

  const zoom = () => {
    if (!radiusInMetres) return 18;
    switch (true) {
      case radiusInMetres < 1000:
        return 18;
      case radiusInMetres < 3000:
        return 14;
      case radiusInMetres < 5000:
        return 13;
      case radiusInMetres < 10000:
        return 12;
      case radiusInMetres < 30000:
        return 11;
      case radiusInMetres < 50000:
        return 10;
      case radiusInMetres < 120000:
        return 9;
      case radiusInMetres < 300000:
        return 8;
      case radiusInMetres < 400000:
        return 7;
      case radiusInMetres >= 400000:
        return 6;
      default:
        return 18;
    }
  };
  return zoom();
};

export const viewJob = (job: Job) => {
  jobActive(job)
    ? navigateTo(`${ROUTES.dashboardJobView}/${job.id}`)
    : navigateTo(`${ROUTES.dashboardArchivedJobView}/${job.id}`);
};

export const getSingleJobFromRouteId = async (): Promise<Job | undefined> => {
  try {
    const route = useRoute();
    const jobStore = useJobStore();

    const { jobs } = storeToRefs(jobStore);

    const jobId = parseInt((route.params as { id: string }).id);
    const foundJob: Job | undefined = jobs.value.find((job: Job) => job.id === jobId);

    if (foundJob) return foundJob;
    return await jobStore.getOne({ id: jobId });
  } catch (error) {
    showNotifyError(ERROR_MESSAGES.job_not_found);
    Sentry.captureException(error);
    return;
  }
};

export const calculateCandidateMatch = ({
  candidate,
  job,
}: {
  candidate: Candidate;
  job?: Job;
}): number | undefined => {
  let score = 0;
  let criteriaChecks = [];

  if (!job) return;

  criteriaChecks.push(
    candidate.desired_remuneration
      ? candidate.desired_remuneration.amount >= job.salary.min! &&
          candidate.desired_remuneration.amount <= job.salary.max!
      : false
  );

  criteriaChecks.push(
    candidate.capacity?.type && job.capacity ? candidate.capacity.type.id === job.capacity.id : false
  );

  criteriaChecks.push(
    candidate.experience?.experience && job.experience
      ? candidate.experience.experience.id === job.experience.id
      : false
  );

  criteriaChecks.push(
    candidate.industry?.industry && job.industry ? candidate.industry.industry.id === job.industry.id : false
  );

  criteriaChecks.push(
    candidate.industry?.industry_position && job.industry_position
      ? candidate.industry.industry_position.id === job.industry_position.id
      : false
  );

  const candidateLanguages = candidate.languages
    ? candidate.languages.map((language) => language.language.id)
    : [];
  const jobLanguages = job.languages ? job.languages.map((language) => language.id) : [];

  jobLanguages.forEach((lang) => {
    if (candidateLanguages.includes(lang)) {
      criteriaChecks.push(true);
    } else {
      criteriaChecks.push(false);
    }
  });

  criteriaChecks.forEach((check) => {
    if (check) score++;
  });

  const percentageMatch = Math.round((score / criteriaChecks.length) * 100);

  return percentageMatch;
};

export const inviteCandidates = (jobId: number) => {
  navigateTo(`${ROUTES.dashboardJobsInvite}/${jobId}`);
};

export const toggleShareJobModal = ({
  active,
  job,
  title,
  showInvite,
}: {
  active: boolean;
  job?: Job;
  title?: string;
  showInvite?: boolean;
}) => {
  const jobStore = useJobStore();
  const { shareJobModal } = storeToRefs(jobStore);
  if (active) {
    shareJobModal.value = { active, job, title: title || "Share Job Post", showInvite };
  } else {
    shareJobModal.value.active = false;
    setTimeout(() => {
      shareJobModal.value = { ...shareJobModal.value, job: undefined, showInvite: false };
    }, 300);
  }
};

function getDistanceInKm(p1: Coordinates, p2: Coordinates): number {
  const R = 6371; // Earth's radius in kilometers
  const dLat = toRad(p2.lat - p1.lat);
  const dLon = toRad(p2.lng - p1.lng);

  const lat1 = toRad(p1.lat);
  const lat2 = toRad(p2.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

function toRad(degrees: number): number {
  return degrees * (Math.PI / 180);
}

export function filterCandidatesByRadius(
  centerPoint: Coordinates,
  items: (Candidate & { similarity: number })[],
  radiusMeters: number
): (Candidate & { similarity: number })[] {
  const radiusKm = radiusMeters / 1000;
  return items.filter((c) => {
    const { lat, lng } = c.location?.data || {};
    return lat && lng && getDistanceInKm(centerPoint, { lat: parseFloat(lat), lng: parseFloat(lng) }) <= radiusKm;
  });
}
